<template>
  <div class="auth-wrapper auth-v2">
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <b-row
      v-else
      class="m-0 registration-global-container"
    >

      <!-- Brand logo-->
      <b-link
        :to="{name:'home'}"
        class="brand-logo"
      >
        <b-img
          :src="appLongLogo"
          alt="logo"
          class="long-logo"
          style="max-width: 120"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-lg-flex align-items-center p-5 registration-image"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <div
        v-if="($route.params.token && $route.params.id && invitedCompanyName)"
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-5 flex-column justify-content-between registration-info-block"
      >
        <div
          class="w-100 registration_invite_container"
        >
          <div class="registration_invite_container__title_container">
            <h2>Company {{ invitedCompanyName }} invited you!</h2>
            <p>Fill in short form to create account & join Test team.</p>
          </div>
          <div v-if="currentRegistrationStep === STEP_STATUSES.FIRST">
            <h3 class="step-title">
              Personal details
            </h3>
            <!-- first_name -->
            <validation-observer
              ref="firstStep"
            >
              <div class="register-row">
                <b-form-group
                  label="First Name*"
                  label-class="label-class"
                  label-for="register-first_name"
                  class="register-row__item"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    vid="first_name"
                    rules="max:255|required"
                  >
                    <b-form-input
                      id="register-first_name"
                      v-model="user.first_name"
                      name="register-first_name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter your first name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- last_name -->
                <b-form-group
                  label-class="label-class"
                  label="Last Name*"
                  label-for="register-last_name"
                  class="register-row__item"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    vid="last_name"
                    rules="max:255|required"
                  >
                    <b-form-input
                      id="register-last_name"
                      v-model="user.last_name"
                      name="register-last_name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter your last name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <!-- email -->
              <b-form-group
                label="Email*"
                label-for="register-email"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="user.email"
                  rules="required|email|max:255"
                >
                  <b-form-input
                    id="register-email"
                    v-model="user.email"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    :disabled="disabledEmail"
                    placeholder="Enter your email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- phone_number -->
              <b-form-group
                label="Telephone 1*"
                label-for="register-phone_number"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telephone"
                  vid="phone_number1"
                  rules="required|numeric|min:5|max:15"
                >
                  <vue-tel-input
                    id="register-phone_number"
                    ref="phoneNumber1"
                    v-model="phoneInfo.phone1"
                    class="form-control pl-0"
                    name="register-phone_number"
                    :preferred-countries="['GB']"
                    :auto-default-country="false"
                    :auto-format="false"
                    :default-country="defaultIso"
                    :dropdown-options="{
                      showDialCodeInSelection: true,
                      showFlags: true,
                      showDialCodeInList: true
                    }"
                  />
                  <small class="text-danger">{{ filterRequiredStep(errors, isFirstStepValidate)[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group v-if="!isAdditionalPhone">
                <b-button
                  id="isAdditionalPhone"
                  variant="transparent"
                  class="add-address-btn"
                  @click.prevent="addAdditionalPhone"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="20"
                    color="#F0554E"
                  />
                  Add second phone number
                </b-button>
              </b-form-group>
              <b-form-group
                v-if="isAdditionalPhone"
                label="Telephone 2*"
                label-for="register-company-phone_number"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telephone 2"
                  vid="phone_number"
                  rules="required|numeric|min:5|max:15"
                >
                  <vue-tel-input
                    id="register-company-phone_number"
                    ref="phoneNumber2"
                    v-model="phoneInfo.phone2"
                    class="form-control pl-0"
                    name="register-company-phone_number"
                    :preferred-countries="['GB']"
                    :auto-default-country="false"
                    :auto-format="false"
                    :default-country="defaultIso"
                    :dropdown-options="{
                      showDialCodeInSelection: true,
                      showFlags: true,
                      showDialCodeInList: true
                    }"
                  />
                  <small class="text-danger">{{ filterRequiredStep(errors, isFirstStepValidate)[0] || phoneRequiredError }}</small>
                </validation-provider>
              </b-form-group>
            </validation-observer>

            <h3 class="step-title mt-3">
              Address details
            </h3>

            <b-form-group>
              <b-form-checkbox
                id="keep_billing"
                v-model="keepBillingOfTheCompany"
                name="checkbox-1"
                :value="1"
                :unchecked-value="0"
              >
                Keep the same as billing address of the company
              </b-form-checkbox>
            </b-form-group>
            <validation-observer
              v-if="!keepBillingOfTheCompany"
              ref="firstStep"
            >
              <b-form-group
                label="Address Line 1*"
                label-for="register-billing-address1"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address Line 1"
                  vid="billing_address1"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="register-billing-address1"
                    v-model="user.address1"
                    name="register-billing-address1"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Address Line 1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- address2 -->
              <b-form-group
                label="Address Line 2"
                label-for="register-billing-address2"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address Line 2"
                  vid="billing_address2"
                  rules="max:255"
                >
                  <b-form-input
                    id="register-billing-address2"
                    v-model="user.address2"
                    name="register-billing-address2"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Address Line 2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Country -->
              <b-form-group
                label="Country*"
                label-for="register-billing-country"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  vid="billing_country_id"
                  rules="required|max:255"
                >

                  <v-select
                    v-model="user.country_id"
                    :options="countryList"
                    :reduce="country_id => country_id.id"
                    placeholder="Select country"
                    label="name"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- region -->
              <b-form-group
                label="Region/State"
                label-for="register-billing-region"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Region/State"
                  vid="billing_state"
                  rules="max:255"
                >
                  <b-form-input
                    id="register-billing-region"
                    v-model="user.state"
                    name="register-billing-region"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Region/State"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- city -->
              <b-form-group
                label="Town/City*"
                label-for="register-billing-city"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Town/City"
                  vid="billing_city"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="register-billing-city"
                    v-model="user.city"
                    name="register-billing-city"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Town/City"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- postcode -->
              <b-form-group
                label="Postcode/Zip Code*"
                label-for="register-billing-postcode"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Postcode/Zip Code"
                  vid="billing_postcode"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="register-billing-postcode"
                    v-model="user.postcode"
                    name="register-billing-postcode"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Postcode/Zip Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </validation-observer>
            <b-button
              variant="primary"
              class="w-100 mt-3"
              @click.prevent="validateStep('firstStep')"
            >
              Confirm
            </b-button>
          </div>
          <div v-if="currentRegistrationStep === STEP_STATUSES.SECOND">
            <h3 class="step-title">
              Create your password
            </h3>
            <validation-observer
              ref="secondStep"
            >
              <b-form-group
                label-for="register-password"
                label="Password*"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="required|password|max:255"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="filterRequiredStep(errors, isSecondStepValidate).length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="user.password"
                      class="form-control-merge"
                      :type="password1FieldType"
                      :state="filterRequiredStep(errors, isSecondStepValidate).length > 0 ? false:null"
                      name="register-password"
                      placeholder="Enter your password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ filterRequiredStep(errors, isSecondStepValidate)[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password_confirmation -->
              <b-form-group
                label-for="register-password_confirmation"
                label="Password Confirmation*"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password Confirmation"
                  vid="password_confirmation"
                  rules="required|confirmed:password|max:255"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="filterRequiredStep(errors, isSecondStepValidate).length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password-confirmation"
                      v-model="user.password_confirmation"
                      class="form-control-merge"
                      :type="password2FieldType"
                      :state="filterRequiredStep(errors, isSecondStepValidate).length > 0 ? false:null"
                      name="register-password-confirmation"
                      placeholder="Enter password once again"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ filterRequiredStep(errors, isSecondStepValidate)[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="is_agreement"
                  v-model="user.is_agreement"
                  name="checkbox-1"
                  :value="1"
                  :unchecked-value="0"
                >
                  I accept <b-link
                    to="/latest"
                    target="_blank"
                  >
                    End User License Agreement
                  </b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="is_subscribe"
                  v-model="user.is_subscribe"
                  name="checkbox-1"
                  :value="1"
                  :unchecked-value="0"
                >
                  I want to receive marketing emails
                </b-form-checkbox>
              </b-form-group>
              <div class="navigation-buttons last-step">
                <b-button
                  variant="primary"
                  class="w-100 prev-btn"
                  @click.prevent="handleBackStep"
                >
                  Back
                </b-button>
                <b-button
                  variant="primary"
                  class="w-100"
                  @click.prevent="register('secondStep')"
                >
                  Sign Up
                </b-button>
              </div>
            </validation-observer>
          </div>
          <div />
        </div>
      </div>
      <!-- Register-->
      <div
        v-if="!($route.params.token && $route.params.id && invitedCompanyName)"
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-5 flex-column justify-content-between registration-info-block"
      >
        <div>
          <div
            v-if="currentRegistrationStep !== STEP_STATUSES.TYPE_SELECTION"
          >
            <h2 class="registration-info-block__title">
              Creating Account
            </h2>
            <div
              class="step-container"
            >
              <button
                class="step-container__step active"
                :class="{'passed': [STEP_STATUSES.SECOND, STEP_STATUSES.THIRD, STEP_STATUSES.FOURTH].includes(currentRegistrationStep), }"
                @click="handleNavigateToTheStep(STEP_STATUSES.FIRST)"
              >
                <feather-icon
                  v-if="[STEP_STATUSES.SECOND, STEP_STATUSES.THIRD, STEP_STATUSES.FOURTH].includes(currentRegistrationStep)"
                  color="#F0554E"
                  icon="CheckIcon"
                  width="16"
                  height="16"
                />
                {{ ![STEP_STATUSES.SECOND, STEP_STATUSES.THIRD, STEP_STATUSES.FOURTH].includes(currentRegistrationStep) ? 1 : '' }}
              </button>
              <div class="step-container__divider" />
              <button
                class="step-container__step"
                :class="{'active': STEP_STATUSES.SECOND === currentRegistrationStep, 'passed': [STEP_STATUSES.THIRD, STEP_STATUSES.FOURTH].includes(currentRegistrationStep)}"
                :disabled="[STEP_STATUSES.FIRST].includes(currentRegistrationStep)"
                @click="handleNavigateToTheStep(STEP_STATUSES.SECOND)"
              >
                <feather-icon
                  v-if="[STEP_STATUSES.THIRD, STEP_STATUSES.FOURTH].includes(currentRegistrationStep)"
                  color="#F0554E"
                  icon="CheckIcon"
                />
                {{ ![STEP_STATUSES.THIRD, STEP_STATUSES.FOURTH].includes(currentRegistrationStep) ? 2 : '' }}
              </button>
              <div class="step-container__divider" />
              <button
                class="step-container__step"
                :class="{'active': STEP_STATUSES.THIRD === currentRegistrationStep, 'passed': [STEP_STATUSES.FOURTH].includes(currentRegistrationStep)}"
                :disabled="[STEP_STATUSES.FIRST, STEP_STATUSES.SECOND].includes(currentRegistrationStep)"
                @click="handleNavigateToTheStep(STEP_STATUSES.THIRD)"
              >
                <feather-icon
                  v-if="[STEP_STATUSES.FOURTH].includes(currentRegistrationStep)"
                  color="#F0554E"
                  icon="CheckIcon"
                />
                {{ ![STEP_STATUSES.FOURTH].includes(currentRegistrationStep) ? 3 : '' }}
              </button>
              <div
                v-if="registrationType === 'company'"
                class="step-container__divider"
              />
              <button
                v-if="registrationType === 'company'"
                class="step-container__step"
                :disabled="[STEP_STATUSES.FIRST, STEP_STATUSES.SECOND, STEP_STATUSES.THIRD].includes(currentRegistrationStep)"
                :class="{'active': [STEP_STATUSES.FOURTH].includes(currentRegistrationStep)}"
                @click="handleNavigateToTheStep(STEP_STATUSES.FOURTH)"
              >
                4
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="currentRegistrationStep === STEP_STATUSES.TYPE_SELECTION"
          class="w-100"
        >
          <h2
            class="registration__title"
          >
            Let's get you signed up!
          </h2>
          <h2
            class="registration__subtitle"
          >
            Please select your type of account:
          </h2>
          <div class="registration__select_type">
            <label
              for="individual"
              class="registration__select"
            >
              <div class="registration__select_title">
                <feather-icon
                  icon="PackageIcon"
                  size="24"
                />
                <p>Individual</p>
              </div>
              <input
                id="individual"
                v-model="registrationType"
                type="radio"
                name="accountType"
                value="individual"
              >
            </label>
            <label
              for="company"
              class="registration__select"
            >
              <div class="registration__select_title">
                <feather-icon
                  icon="UserIcon"
                  size="24"
                />
                <p>Company</p>
              </div>
              <input
                id="company"
                v-model="registrationType"
                type="radio"
                name="accountType"
                value="company"
              >
            </label>
          </div>

          <b-button
            variant="primary w-100"
            @click.prevent="handleNextStep"
          >
            Submit
          </b-button>
        </div>
        <div
          v-if="currentRegistrationStep === STEP_STATUSES.FIRST"
          class="w-100"
        >
          <div v-if="registrationType==='individual'">
            <h3 class="step-title">
              Personal details
            </h3>
            <!-- first_name -->
            <validation-observer
              ref="firstStep"
            >
              <b-form-group
                label="First Name*"
                label-class="label-class"
                label-for="register-first_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  vid="first_name"
                  rules="max:255|required"
                >
                  <b-form-input
                    id="register-first_name"
                    v-model="user.first_name"
                    name="register-first_name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter your first name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- last_name -->
              <b-form-group
                label-class="label-class"
                label="Last Name*"
                label-for="register-last_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  vid="last_name"
                  rules="max:255|required"
                >
                  <b-form-input
                    id="register-last_name"
                    v-model="user.last_name"
                    name="register-last_name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter your last name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- email -->
              <b-form-group
                label="Email*"
                label-for="register-email"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="user.email"
                  rules="required|email|max:255"
                >
                  <b-form-input
                    id="register-email"
                    v-model="user.email"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    :disabled="disabledEmail"
                    placeholder="Enter your email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- phone_number -->
              <div>
                <b-form-group
                  label="Telephone 1*"
                  label-for="register-phone_number"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Telephone"
                    vid="phone_number1"
                    rules="required|numeric|min:5|max:15"
                  >
                    <vue-tel-input
                      id="register-phone_number"
                      ref="phoneNumber1"
                      v-model="phoneInfo.phone1"
                      class="form-control pl-0"
                      name="register-phone_number"
                      :preferred-countries="['GB']"
                      :auto-default-country="false"
                      :auto-format="false"
                      :default-country="defaultIso"
                      :dropdown-options="{
                        showDialCodeInSelection: true,
                        showFlags: true,
                        showDialCodeInList: true
                      }"
                    />
                    <small
                      class="text-danger"
                    >{{ filterRequiredStep(errors, isFirstStepValidate)[0] || phoneRequiredError }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <b-form-group v-if="!isAdditionalPhone">
                <b-button
                  id="isAdditionalPhone"
                  variant="transparent"
                  class="add-address-btn"
                  @click.prevent="addAdditionalPhone"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="20"
                    color="#F0554E"
                  />
                  Add second phone number
                </b-button>
              </b-form-group>
              <b-form-group
                v-if="isAdditionalPhone"
                label="Telephone 2*"
                label-for="register-company-phone_number"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telephone 2"
                  vid="phone_number"
                  rules="required|numeric|min:5|max:15"
                >
                  <vue-tel-input
                    id="register-company-phone_number"
                    ref="phoneNumber2"
                    v-model="phoneInfo.phone2"
                    class="form-control pl-0"
                    name="register-company-phone_number"
                    :preferred-countries="['GB']"
                    :auto-default-country="false"
                    :auto-format="false"
                    :default-country="defaultIso"
                    :dropdown-options="{
                      showDialCodeInSelection: true,
                      showFlags: true,
                      showDialCodeInList: true
                    }"
                  />
                  <small class="text-danger">{{ filterRequiredStep(errors, isFirstStepValidate)[0] || phoneRequiredError }}</small>
                </validation-provider>
              </b-form-group>
            </validation-observer>
          </div>
          <div v-if="registrationType==='company' ">
            <h3 class="step-title">
              Company details
            </h3>
            <validation-observer
              ref="firstStep"
            >
              <!-- first_name -->
              <b-form-group
                label="Company Name*"
                label-class="label-class"
                label-for="register-company-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company Name"
                  vid="name"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="register-company-name"
                    v-model="company.name"
                    name="register-company-name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Company Name"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Company Registered Number"
                label-class="label-class"
                label-for="register-company-number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company Registered Number"
                  vid="registered_number"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="register-company-number"
                    v-model="company.registered_number"
                    name="register-company-number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Company Registered Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="register-row">
                <b-form-group
                  label="VAT ID"
                  label-class="label-class"
                  label-for="register-company-vat"
                  class="register-row__item"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="VAT ID"
                    vid="vat_id"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="register-company-vat"
                      v-model="company.vat_id"
                      name="register-company-vat"
                      :state="errors.length > 0 ? false:null"
                      placeholder="VAT ID"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Currency"
                  label-for="register-currency"
                  label-class="label-class"
                  class="register-row__item"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Currency"
                    vid="currency"
                    rules="required|max:255"
                  >

                    <v-select
                      v-model="company.currency"
                      :options="currencyList"
                      :reduce="currency_id => currency_id.id"
                      placeholder="Select currency"
                      label="name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <b-form-group
                label="Telephone*"
                label-for="register-company-phone_number"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telephone"
                  vid="phoneInfo.company_phone"
                  rules="required|numeric|min:5|max:15"
                >
                  <vue-tel-input
                    id="register-company-phone_number"
                    ref="phoneNumber1"
                    v-model="phoneInfo.company_phone"
                    class="form-control pl-0"
                    name="register-company-phone_number"
                    :preferred-countries="['GB']"
                    :auto-default-country="false"
                    :auto-format="false"
                    :default-country="defaultIso"
                    :dropdown-options="{
                      showDialCodeInSelection: true,
                      showFlags: true,
                      showDialCodeInList: true
                    }"
                  />
                  <small class="text-danger">{{ filterRequiredStep(errors, isFirstStepValidate)[0] || phoneRequiredError }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Email*"
                label-for="register-company-email"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="company.email"
                  rules="required|email|max:255"
                >
                  <b-form-input
                    id="register-company-email"
                    v-model="company.email"
                    name="register-company-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="company@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Web"
                label-for="register-company-web"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Web"
                  vid="web"
                  rules="max:255|url"
                >
                  <b-form-input
                    id="register-company-web"
                    v-model="company.web"
                    name="register-company-web"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </validation-observer>
          </div>
          <b-button
            variant="primary"
            class="w-100 mt-2"
            @click.prevent="validateStep('firstStep')"
          >
            Next Step
          </b-button>
        </div>

        <div
          v-if="currentRegistrationStep === STEP_STATUSES.SECOND"
          class="w-100"
        >
          <div v-if="registrationType==='individual'">
            <h3 class="step-title">
              Billing Address
            </h3>
            <validation-observer
              ref="secondStep"
            >
              <div>
                <b-form-group
                  label="Email*"
                  label-for="register-email-123"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="user.billing_email"
                    rules="required|email|max:255"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="user.billing_email"
                      name="register-email123"
                      :state="filterRequiredStep(errors, isSecondStepValidate).length > 0 ? false:null"
                      placeholder="Enter your email for billing operations"
                    />
                    <small
                      class="text-danger"
                    >{{ filterRequiredStep(errors, isSecondStepValidate)[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div>
                <b-form-group
                  label="Address Line 1*"
                  label-for="register-billing-address1"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Address Line 1"
                    vid="user.billing_address1"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="register-billing-address1"
                      v-model="user.billing_address1"
                      name="register-billing-address1"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter Address Line 1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <!-- address2 -->
              <b-form-group
                label="Address Line 2"
                label-for="register-billing-address2"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address Line 2"
                  vid="user.billing_address2"
                  rules="max:255"
                >
                  <b-form-input
                    id="register-billing-address2"
                    v-model="user.billing_address2"
                    name="register-billing-address2"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter Address Line 2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Country -->
              <div>

                <b-form-group
                  label="Country*"
                  label-for="register-billing-country"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    vid="billing_country_id"
                    rules="required|max:255"
                  >

                    <v-select
                      v-model="user.billing_country_id"
                      :options="countryList"
                      :reduce="billing_country_id => billing_country_id.id"
                      placeholder="Select your country"
                      label="name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <!-- region -->
              <b-form-group
                label="Region/State"
                label-for="register-billing-region"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Region/State"
                  vid="billing_state"
                  rules="max:255"
                >
                  <b-form-input
                    id="register-billing-region"
                    v-model="user.billing_state"
                    name="register-billing-region"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Select region or state"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="register-row">
                <!-- city -->
                <b-form-group
                  label="Town/City*"
                  label-for="register-billing-city"
                  label-class="label-class"
                  class="register-row__item"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Town/City"
                    vid="billing_city"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="register-billing-city"
                      v-model="user.billing_city"
                      name="register-billing-city"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Select town"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- postcode -->
                <b-form-group
                  label="Postcode/Zip Code*"
                  label-for="register-billing-postcode"
                  label-class="label-class"
                  class="register-row__item"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Postcode/Zip Code"
                    vid="billing_postcode"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="register-billing-postcode"
                      v-model="user.billing_postcode"
                      name="register-billing-postcode"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter your code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <h3 class="step-title">
                Shipping address
              </h3>
              <b-form-group>
                <b-form-checkbox
                  id="keep_billing"
                  v-model="individualShippingAddressSameAsBilling"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                >
                  Shipping address same as billing
                </b-form-checkbox>
              </b-form-group>
              <div v-if="!individualShippingAddressSameAsBilling">
                <div>
                  <b-form-group
                    label="Address Line 1*"
                    label-for="register-shipping-address1"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Address Line 1"
                      vid="billing_address1"
                      rules="required|max:255"
                    >
                      <b-form-input
                        id="register-shipping-address1"
                        v-model="user.shipping_address1"
                        name="register-shipping-address1"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Address Line 1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <!-- address2 -->
                <b-form-group
                  label="Address Line 2"
                  label-for="register-shipping-address2"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Address Line 2"
                    vid="billing_address2"
                    rules="max:255"
                  >
                    <b-form-input
                      id="register-shipping-address2"
                      v-model="user.shipping_address2"
                      name="register-shipping-address2"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter Address Line 2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Country -->
                <b-form-group
                  label="Country*"
                  label-for="register-shipping-country"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    vid="shipping_country_id"
                    rules="required|max:255"
                  >

                    <v-select
                      v-model="user.shipping_country_id"
                      :options="countryList"
                      :reduce="shipping_country_id => shipping_country_id.id"
                      placeholder="Select your country"
                      label="name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- region -->
                <b-form-group
                  label="Region/State"
                  label-for="register-shipping-region"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Region/State"
                    vid="billing_state"
                    rules="max:255"
                  >
                    <b-form-input
                      id="register-shipping-region"
                      v-model="user.shipping_state"
                      name="register-shipping-region"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Select region or state"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <div class="register-row">
                  <!-- city -->
                  <b-form-group
                    label="Town/City*"
                    label-for="register-shipping-city"
                    label-class="label-class"
                    class="register-row__item"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Town/City"
                      vid="billing_city"
                      rules="required|max:255"
                    >
                      <b-form-input
                        id="register-shipping-city"
                        v-model="user.shipping_city"
                        name="register-shipping-city"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Select town"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- postcode -->
                  <b-form-group
                    label="Postcode/Zip Code*"
                    label-for="register-shipping-postcode"
                    label-class="label-class"
                    class="register-row__item"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Postcode/Zip Code"
                      vid="shipping_postcode"
                      rules="required|max:255"
                    >
                      <b-form-input
                        id="register-shipping-postcode"
                        v-model="user.shipping_postcode"
                        name="register-shipping-postcode"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter your code"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
            </validation-observer>
          </div>
          <div v-if="registrationType==='company' ">
            <h3 class="step-title">
              Billing Address
            </h3>

            <validation-observer
              ref="secondStep"
            >
              <b-form-group
                label="Email*"
                label-for="register-company-email"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="company.billing_email"
                  rules="required|email|max:255"
                >
                  <b-form-input
                    id="register-company-email"
                    v-model="company.billing_email"
                    name="register-company-email"
                    :state="filterRequiredStep(errors, isSecondStepValidate).length > 0 ? false : null"
                    placeholder="company@example.com"
                    :disabled="isBillingEmailDisabled"
                  />
                  <small class="text-danger">{{ filterRequiredStep(errors, isSecondStepValidate)[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  id="same_billing_email"
                  v-model="isBillingEmailDisabled"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                >
                  Keep the same as company contact email
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                label="Address Line 1*"
                label-for="register-billing-address1"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address Line 1"
                  vid="billing_address1"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="register-billing-address1"
                    v-model="company.billing_address1"
                    name="register-billing-address1"
                    :state="filterRequiredStep(errors, isSecondStepValidate).length > 0 ? false:null"
                    placeholder="Address Line 1"
                  />
                  <small class="text-danger">{{ filterRequiredStep(errors, isSecondStepValidate)[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- address2 -->
              <b-form-group
                label="Address Line 2"
                label-for="register-billing-address2"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address Line 2"
                  vid="billing_address2"
                  rules="max:255"
                >
                  <b-form-input
                    id="register-billing-address2"
                    v-model="company.billing_address2"
                    name="register-billing-address2"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Address Line 2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Country -->
              <b-form-group
                label="Country*"
                label-for="register-billing-country"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  vid="billing_country_id"
                  rules="required|max:255"
                >

                  <v-select
                    v-model="company.billing_country_id"
                    :options="countryList"
                    :reduce="country_id => country_id.id"
                    placeholder="Select country"
                    label="name"
                  />

                  <small class="text-danger">{{ filterRequiredStep(errors, isSecondStepValidate)[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- region -->
              <b-form-group
                label="Region/State"
                label-for="register-billing-region"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Region/State"
                  vid="billing_state"
                  rules="max:255"
                >
                  <b-form-input
                    id="register-billing-region"
                    v-model="company.billing_state"
                    name="register-billing-region"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Region/State"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- city -->
              <b-form-group
                label="Town/City*"
                label-for="register-billing-city"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Town/City"
                  vid="billing_city"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="register-billing-city"
                    v-model="company.billing_city"
                    name="register-billing-city"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Town/City"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- postcode -->
              <b-form-group
                label="Postcode/Zip Code*"
                label-for="register-billing-postcode"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Postcode/Zip Code"
                  vid="billing_postcode"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="register-billing-postcode"
                    v-model="company.billing_postcode"
                    name="register-billing-postcode"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Postcode/Zip Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-button
                  id="isSameAddress"
                  variant="transparent"
                  class="add-address-btn"
                  @click.prevent="addShippingAddress"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="20"
                    color="#F0554E"
                  />
                  Add Shipping Address
                </b-button>
              </b-form-group>
              <div v-if="!isSameAddress">
                <h3 class="step-title mt-3">
                  Shipping address:
                </h3>
                <div class="px-50">
                  <b-form-group
                    label="Address Line 1"
                    label-for="register-shipping-address1"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Address Line 1"
                      vid="shipping_address1"
                      rules="required|max:255"
                    >
                      <b-form-input
                        id="register-shipping-address1"
                        v-model="company.shipping_address1"
                        name="register-shipping-address1"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Address Line 1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- address2 -->
                  <b-form-group
                    label="Address Line 2"
                    label-for="register-shipping-address2"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Address Line 2"
                      vid="shipping_address1"
                      rules="max:255"
                    >
                      <b-form-input
                        id="register-shipping-address2"
                        v-model="company.shipping_address2"
                        name="register-shipping-address2"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Address Line 2"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Country -->
                  <b-form-group
                    label="Country"
                    label-for="register-shipping-country"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Country"
                      vid="shipping_country_id"
                      rules="required|max:255"
                    >

                      <v-select
                        v-model="company.shipping_country_id"
                        :options="countryList"
                        :reduce="country_id => country_id.id"
                        placeholder="Select country"
                        label="name"
                        :state="errors.length > 0 ? false:null"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- region -->
                  <b-form-group
                    label="Region/State"
                    label-for="register-shipping-region"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Region/State"
                      vid="shipping_state"
                      rules="max:255"
                    >
                      <b-form-input
                        id="register-shipping-region"
                        v-model="company.shipping_state"
                        name="register-shipping-region"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Region/State"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- city -->
                  <b-form-group
                    label="Town/City*"
                    label-for="register-shipping-city"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Town/City"
                      vid="shipping_city"
                      rules="required|max:255"
                    >
                      <b-form-input
                        id="register-shipping-city"
                        v-model="company.shipping_city"
                        name="register-shipping-city"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Town/City"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- postcode -->
                  <b-form-group
                    label="Postcode/Zip Code"
                    label-for="register-shipping-postcode"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Postcode/Zip Code"
                      vid="shipping_postcode"
                      rules="required|max:255"
                    >
                      <b-form-input
                        id="register-shipping-postcode"
                        v-model="company.shipping_postcode"
                        name="register-shipping-postcode"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Postcode/Zip Code"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
            </validation-observer>
          </div>
          <div class="navigation-buttons">
            <b-button
              variant="primary"
              class="w-50 prev-btn"
              @click.prevent="handleBackStep"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="20"
                color="#F0554E"
              />
              Previous Step
            </b-button>
            <b-button
              variant="primary"
              class="w-50"
              @click.prevent="validateStep('secondStep')"
            >
              Next Step
              <feather-icon
                icon="ArrowRightIcon"
                size="20"
                color="#fff"
              />
            </b-button>
          </div>
        </div>

        <div
          v-if="currentRegistrationStep === STEP_STATUSES.THIRD"
          class="w-100"
        >
          <div v-if="registrationType==='individual'">
            <h3 class="step-title">
              Create your password
            </h3>
            <validation-observer
              ref="thirdStep"
            >
              <div>
                <b-form-group
                  label-for="register-password"
                  label="Password*"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    vid="password"
                    rules="required|password|max:255"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="filterRequiredStep(errors, isThirdStepValidate).length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="user.password"
                        class="form-control-merge"
                        :type="password1FieldType"
                        :state="filterRequiredStep(errors, isThirdStepValidate).length > 0 ? false:null"
                        name="register-password"
                        placeholder="Enter your password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      class="text-danger"
                    >{{ filterRequiredStep(errors, isThirdStepValidate)[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <!-- password_confirmation -->
              <div>
                <b-form-group
                  label-for="register-password_confirmation"
                  label="Password Confirmation*"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password Confirmation"
                    vid="password_confirmation"
                    rules="required|confirmed:password|max:255"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="filterRequiredStep(errors, isThirdStepValidate).length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="register-password-confirmation"
                        v-model="user.password_confirmation"
                        class="form-control-merge"
                        :type="password2FieldType"
                        :state="filterRequiredStep(errors, isThirdStepValidate).length > 0 ? false:null"
                        name="register-password-confirmation"
                        placeholder="Enter password once again"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ filterRequiredStep(errors, isThirdStepValidate)[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <b-form-group>
                <b-form-checkbox
                  id="is_agreement"
                  v-model="user.is_agreement"
                  name="checkbox-1"
                  :value="1"
                  :unchecked-value="0"
                >
                  I accept <b-link
                    to="/latest"
                    target="_blank"
                  >
                    End User License Agreement
                  </b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="is_subscribe"
                  v-model="user.is_subscribe"
                  name="checkbox-1"
                  :value="1"
                  :unchecked-value="0"
                >
                  I want to receive marketing emails
                </b-form-checkbox>
              </b-form-group>
              <div class="navigation-buttons last-step">
                <b-button
                  variant="primary"
                  class="w-100 prev-btn"
                  @click.prevent="handleBackStep"
                >
                  Back
                </b-button>
                <b-button
                  variant="primary"
                  class="w-100"
                  @click.prevent="register('thirdStep')"
                >
                  Sign Up
                </b-button>
              </div>
            </validation-observer>
          </div>
          <div v-if="registrationType==='company'">
            <h3 class="step-title">
              Personal details
            </h3>
            <validation-observer
              ref="thirdStep"
            >
              <div class="register-row">
                <!-- first_name -->
                <b-form-group
                  label="First Name*"
                  label-class="label-class"
                  label-for="register-first_name"
                  class="register-row__item"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    vid="first_name"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="register-first_name"
                      v-model="user.first_name"
                      name="register-first_name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="John"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- last_name -->
                <b-form-group
                  label-class="label-class"
                  label="Last Name*"
                  label-for="register-last_name"
                  class="register-row__item"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    vid="last_name"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="register-last_name"
                      v-model="user.last_name"
                      name="register-last_name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Doe"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <!-- email -->
              <b-form-group
                label="Email*"
                label-for="register-email"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="user.email"
                  rules="required|email|max:255"
                >
                  <b-form-input
                    id="register-email"
                    v-model="user.email"
                    name="register-email"
                    :state="filterRequiredStep(errors, isThirdStepValidate).length > 0 ? false:null"
                    :disabled="disabledEmail"
                    placeholder="Enter your email"
                  />
                  <small class="text-danger">{{ filterRequiredStep(errors, isThirdStepValidate)[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Telephone*"
                label-for="register-company-phone_number"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telephone"
                  vid="phone_number"
                  rules="required|numeric|min:5|max:15"
                >
                  <vue-tel-input
                    id="register-company-phone_number"
                    ref="companyPhoneNumber"
                    v-model="phoneInfo.phone1"
                    class="form-control pl-0"
                    name="register-company-phone_number"
                    :preferred-countries="['GB']"
                    :auto-default-country="false"
                    :auto-format="false"
                    :default-country="defaultIso"
                    :dropdown-options="{
                      showDialCodeInSelection: true,
                      showFlags: true,
                      showDialCodeInList: true
                    }"
                  />
                  <small class="text-danger">{{ filterRequiredStep(errors, isThirdStepValidate)[0] || phoneRequiredError }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group v-if="!isAdditionalPhone">
                <b-button
                  id="isAdditionalPhone"
                  variant="transparent"
                  class="add-address-btn"
                  @click.prevent="addAdditionalPhone"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="20"
                    color="#F0554E"
                  />
                  Add second phone number
                </b-button>
              </b-form-group>
              <b-form-group
                v-if="isAdditionalPhone"
                label="Telephone*"
                label-for="register-company-phone_number"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telephone 2"
                  vid="phone_number"
                  rules="numeric|min:5|max:15"
                >
                  <vue-tel-input
                    id="register-company-phone_number"
                    ref="companyPhoneNumber2"
                    v-model="company.phone2"
                    class="form-control pl-0"
                    name="register-company-phone_number"
                    :preferred-countries="['GB']"
                    :auto-default-country="false"
                    :auto-format="false"
                    :default-country="defaultIso"
                    :dropdown-options="{
                      showDialCodeInSelection: true,
                      showFlags: true,
                      showDialCodeInList: true
                    }"
                  />
                  <small class="text-danger">{{ filterRequiredStep(errors, isThirdStepValidate)[0] || phoneRequiredError }}</small>
                </validation-provider>
              </b-form-group>

              <h3 class="step-title mt-3">
                Address details
              </h3>

              <b-form-group>
                <b-form-checkbox
                  id="keep_billing"
                  v-model="keepBillingOfTheCompany"
                  name="checkbox-1"
                  :value="1"
                  :unchecked-value="0"
                >
                  Keep the same as billing address of the company
                </b-form-checkbox>
              </b-form-group>

              <validation-observer
                v-if="!keepBillingOfTheCompany"
                ref="thirdStep"
              >
                <b-form-group
                  label="Address Line 1*"
                  label-for="register-billing-address1"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Address Line 1"
                    vid="billing_address1"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="register-billing-address1"
                      v-model="user.address1"
                      name="register-billing-address1"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Address Line 1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- address2 -->
                <b-form-group
                  label="Address Line 2"
                  label-for="register-billing-address2"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Address Line 2"
                    vid="billing_address2"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="register-billing-address2"
                      v-model="user.address2"
                      name="register-billing-address2"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Address Line 2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Country -->
                <b-form-group
                  label="Country*"
                  label-for="register-billing-country"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    vid="billing_country_id"
                    rules="required|max:255"
                  >

                    <v-select
                      v-model="user.country_id"
                      :options="countryList"
                      :reduce="country_id => country_id.id"
                      placeholder="Select country"
                      label="name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- region -->
                <b-form-group
                  label="Region/State"
                  label-for="register-billing-region"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Region/State"
                    vid="billing_state"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="register-billing-region"
                      v-model="user.state"
                      name="register-billing-region"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Region/State"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- city -->
                <b-form-group
                  label="Town/City*"
                  label-for="register-billing-city"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Town/City"
                    vid="billing_city"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="register-billing-city"
                      v-model="user.city"
                      name="register-billing-city"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Town/City"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- postcode -->
                <b-form-group
                  label="Postcode/Zip Code*"
                  label-for="register-billing-postcode"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Postcode/Zip Code"
                    vid="billing_postcode"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="register-billing-postcode"
                      v-model="user.postcode"
                      name="register-billing-postcode"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Postcode/Zip Code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </validation-observer>

              <div class="navigation-buttons">
                <b-button
                  variant="primary"
                  class="w-50 prev-btn"
                  @click.prevent="handleBackStep"
                >
                  Previous Step
                </b-button>
                <b-button
                  variant="primary"
                  class="w-50"
                  @click.prevent="validateStep('thirdStep')"
                >
                  Next Step
                </b-button>
              </div>
            </validation-observer>
          </div>
        </div>
        <div
          v-if="currentRegistrationStep === STEP_STATUSES.FOURTH"
          class="w-100"
        >
          <div v-if="registrationType==='company'">
            <validation-observer
              ref="fourthStep"
            >
              <h3 class="step-title">
                Create your password
              </h3>
              <b-form-group
                label-for="register-password"
                label="Password*"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="required|password|max:255"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="filterRequiredStep(errors, isFourthStepValidate).length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="user.password"
                      class="form-control-merge"
                      :type="password1FieldType"
                      :state="filterRequiredStep(errors, isFourthStepValidate).length > 0 ? false:null"
                      name="register-password"
                      placeholder=""
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ filterRequiredStep(errors, isFourthStepValidate)[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password_confirmation -->
              <b-form-group
                label-for="register-password_confirmation"
                label="Password Confirmation*"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password Confirmation"
                  vid="password_confirmation"
                  rules="required|confirmed:password|max:255"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="filterRequiredStep(errors, isFourthStepValidate).length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password-confirmation"
                      v-model="user.password_confirmation"
                      class="form-control-merge"
                      :type="password2FieldType"
                      :state="filterRequiredStep(errors, isFourthStepValidate).length > 0 ? false:null"
                      name="register-password-confirmation"
                      placeholder=""
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ filterRequiredStep(errors, isFourthStepValidate)[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="is_agreement"
                  v-model="user.is_agreement"
                  name="checkbox-1"
                  :value="1"
                  :unchecked-value="0"
                >
                  I accept <b-link
                    to="/latest"
                    target="_blank"
                  >
                    End User License Agreement
                  </b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="is_subscribe"
                  v-model="user.is_subscribe"
                  name="checkbox-1"
                  :value="1"
                  :unchecked-value="0"
                >
                  I want to receive marketing emails
                </b-form-checkbox>
              </b-form-group>
              <div class="navigation-buttons last-step">
                <b-button
                  variant="primary"
                  class="w-100 prev-btn"
                  @click.prevent="handleBackStep"
                >
                  Back
                </b-button>
                <b-button
                  variant="primary"
                  class="w-100"
                  @click.prevent="register('fourthStep')"
                >
                  Sign Up
                </b-button>
              </div>
            </validation-observer>
          </div>
        </div>
        <div>
          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;Login</span>
            </b-link>
          </p>
        </div>
      </div>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>

/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow, BCol, BLink, BButton, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, VBTooltip, BSpinner,
} from 'bootstrap-vue'
import {
  required, confirmed, email, min, max, numeric, password, url,
} from '@validations'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import { $themeConfig } from '@themeConfig'

const VueTelInput = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
// eslint-disable-next-line no-shadow
]).then(([{ VueTelInput }]) => VueTelInput)

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    // validationsfv
    ValidationProvider,
    ValidationObserver,
    VueTelInput,
    vSelect,
  },
  setup() {
    const { appLongLogo } = $themeConfig.app

    return {
      appLongLogo,
    }
  },
  data() {
    return {
      STEP_STATUSES: {
        TYPE_SELECTION: 'type_selection',
        FIRST: 'first',
        SECOND: 'second',
        THIRD: 'third',
        FOURTH: 'fourth',
      },
      currentRegistrationStep: 'type_selection',
      registrationType: 'individual',
      keepBillingOfTheCompany: 1,
      isBillingEmailDisabled: false,
      individualShippingAddressSameAsBilling: true,

      isFirstStepValidate: false,
      isSecondStepValidate: false,
      isThirdStepValidate: false,
      isFourthStepValidate: false,

      inviteLinkCompany: false,
      invitedCompanyName: '',

      isAdditionalPhone: false,
      isSameAddress: true,
      isUserAddressSameAsCompany: false,
      loading: false,
      phoneRequiredError: '',
      buttonDisabled: false,
      company: {
        name: '',
        registered_number: '',
        vat_id: '',
        currency: null,
        phone_number: null,
        phone_country_iso: null,
        phone_country_code: null,
        email: '',
        web: '',
        billing_address1: '',
        billing_address2: '',
        billing_country_id: null,
        billing_postcode: '',
        billing_state: '',
        billing_city: '',
        shipping_address1: '',
        shipping_address2: '',
        shipping_country_id: null,
        shipping_postcode: '',
        shipping_state: '',
        shipping_city: '',
        billing_email: '',
      },
      phoneInfo: {
        phone1: '',
        phone_country_iso1: '',
        phone_country_code1: '',

        phone2: '',
        phone_country_iso2: '',
        phone_country_code2: '',

        company_phone: '',
        company_phone_country_iso: '',
        company_phone_country_code: '',
      },
      user: {
        address1: '',
        address2: '',
        first_name: '',
        last_name: '',
        phone_number1: '',
        phone_number2: '',
        email: '',
        country_id: '',
        postcode: '',
        state: '',
        city: '',
        password: '',
        password_confirmation: '',
        billing_email: '',
        is_agreement: 0,
        is_subscribe: 0,
      },
      showUserRegistration: false,
      isNewCompany: true,
      disabledEmail: false,
      sideImg: require('@/assets/images/pages/register-v2.svg'),

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',

      // validation
      required,
      email,
      confirmed,
      min,
      max,
      numeric,
      password,
      url,
    }
  },
  computed: {
    ...mapState('register', ['countryList', 'defaultIso', 'currencyList']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    defaultIso(val) {
      this.$refs.phoneNumber1.choose(val)
      this.$refs.phoneNumber2.choose(val)
    },
    // eslint-disable-next-line func-names
    'company.phone_number': function (val) {
      if (val) {
        this.phoneRequiredError = ''
      }
    },
    // eslint-disable-next-line func-names
    'user.phone_number1': function (val) {
      if (val) {
        this.phoneRequiredError = ''
      }
    },
    isSameAddress(val) {
      if (val) {
        this.company.shipping_address1 = this.company.billing_address1
        this.company.shipping_address2 = this.company.billing_address2
        this.company.shipping_country_id = this.company.billing_country_id
        this.company.shipping_postcode = this.company.billing_postcode
        this.company.shipping_state = this.company.billing_state
        this.company.shipping_city = this.company.billing_city
      }
    },
    isUserAddressSameAsCompany(val) {
      if (val) {
        this.user.address1 = this.company.billing_address1
        this.user.address2 = this.company.billing_address2
        this.user.country_id = this.company.billing_country_id
        this.user.postcode = this.company.billing_postcode
        this.user.state = this.company.billing_state
        this.user.city = this.company.billing_city
      }
    },
    company() {
      console.log('company', this.company)
    },
    isBillingEmailDisabled() {
      if (this.isBillingEmailDisabled) {
        this.company.billing_email = this.company.email
      } else {
        this.company.billing_email = ''
      }
    },
  },
  async created() {
    const { token, id } = this.$route.params
    if (token && id) {
      try {
        this.loading = true
        const data = await this.fetchAuthInviteLists({ token, id })
        this.user.email = data.data.email
        this.disabledEmail = true
        if (data.data.company && data.data.company.name) {
          this.user.email = data.data.email
          this.company = { ...data.data.company }
          this.invitedCompanyName = data.data.company.name
          this.isNewCompany = false
          this.showUserRegistration = true
        }
        this.loading = false
        if (data.data.company) {
          this.currentRegistrationStep = this.STEP_STATUSES.FIRST
          this.inviteLinkCompany = true
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Wrong invite link!',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })

        this.$router.push({ name: 'auth-register' })
        this.currentRegistrationStep = this.STEP_STATUSES.TYPE_SELECTION
        this.loading = false
      }
    } else {
      await this.fetchAuthLists()
    }
    if (this.defaultIso) {
      this.user.country_id = this.countryList.find(country => country.iso === this.defaultIso).id
    }
  },
  methods: {
    ...mapActions('register', ['fetchAuthLists', 'fetchAuthInviteLists']),
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    addShippingAddress() {
      this.isSameAddress = !this.isSameAddress
    },
    addAdditionalPhone() {
      this.isAdditionalPhone = !this.isAdditionalPhone
    },
    filterRequiredStep(errors, isActive) {
      if (isActive) return errors
      return errors.filter(e => !e.includes('field is required'))
    },
    handleNextStep() {
      if (this.currentRegistrationStep === this.STEP_STATUSES.TYPE_SELECTION) {
        this.currentRegistrationStep = this.STEP_STATUSES.FIRST
      } else if (this.currentRegistrationStep === this.STEP_STATUSES.FIRST) {
        const phone1Iso = this.$refs.phoneNumber1.phoneObject.country.iso2
        const phone1Code = this.$refs.phoneNumber1.phoneObject.country.dialCode

        const phone2Iso = this.$refs.phoneNumber2 ? this.$refs.phoneNumber2.phoneObject.country.iso2 : null
        const phone2Code = this.$refs.phoneNumber2 ? this.$refs.phoneNumber2.phoneObject.country.dialCode : null

        if (this.registrationType === 'individual') {
          this.phoneInfo = {
            ...this.phoneInfo,

            phone_country_iso1: phone1Iso,
            phone_country_code1: phone1Code,
            phone_country_iso2: phone2Iso,
            phone_country_code2: phone2Code,
          }
        } else {
          this.phoneInfo = {
            ...this.phoneInfo,
            company_phone_country_iso: phone1Iso,
            company_phone_country_code: phone1Code,
          }
        }

        this.currentRegistrationStep = this.STEP_STATUSES.SECOND
      } else if (this.currentRegistrationStep === this.STEP_STATUSES.SECOND) {
        this.currentRegistrationStep = this.STEP_STATUSES.THIRD
      } else if (this.currentRegistrationStep === this.STEP_STATUSES.THIRD) {
        const phone1Iso = this.$refs.companyPhoneNumber.phoneObject.country.iso2
        const phone1Code = this.$refs.companyPhoneNumber.phoneObject.country.dialCode

        const phone2Iso = this.$refs.companyPhoneNumber2 ? this.$refs.companyPhoneNumber2.phoneObject.country.iso2 : null
        const phone2Code = this.$refs.companyPhoneNumber2 ? this.$refs.companyPhoneNumber2.phoneObject.country.dialCode : null

        this.phoneInfo = {
          ...this.phoneInfo,

          phone_country_iso1: phone1Iso,
          phone_country_code1: phone1Code,
          phone_country_iso2: phone2Iso,
          phone_country_code2: phone2Code,
        }

        this.currentRegistrationStep = this.STEP_STATUSES.FOURTH
      }
    },
    handleBackStep() {
      if (this.currentRegistrationStep === this.STEP_STATUSES.FIRST) {
        this.currentRegistrationStep = this.STEP_STATUSES.TYPE_SELECTION
      } else if (this.currentRegistrationStep === this.STEP_STATUSES.SECOND) {
        this.currentRegistrationStep = this.STEP_STATUSES.FIRST
      } else if (this.currentRegistrationStep === this.STEP_STATUSES.THIRD) {
        this.currentRegistrationStep = this.STEP_STATUSES.SECOND
      } else if (this.currentRegistrationStep === this.STEP_STATUSES.FOURTH) {
        this.currentRegistrationStep = this.STEP_STATUSES.THIRD
      }
    },
    handleNavigateToTheStep(step) {
      this.currentRegistrationStep = step
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async validateStep(refName, skipNextStep) {
      const valid = await this.$refs[refName].validate()

      if (valid) {
        if (refName === 'firstStep') {
          this.isFirstStepValidate = false
        } else if (refName === 'secondStep') {
          this.isSecondStepValidate = false
        } else if (refName === 'thirdStep') {
          this.isThirdStepValidate = false
        } else if (refName === 'fourthStep') {
          this.isFourthStepValidate = false
        }

        if (!skipNextStep) {
          this.handleNextStep()
        }
      } else if (refName === 'firstStep') {
        this.isFirstStepValidate = true
      } else if (refName === 'secondStep') {
        this.isSecondStepValidate = true
      } else if (refName === 'thirdStep') {
        this.isThirdStepValidate = true
      } else if (refName === 'fourthStep') {
        this.isFourthStepValidate = true
      }

      return valid
    },
    async register(step) {
      const isValid = await this.validateStep(step, true)

      if (!isValid) {
        return
      }

      this.buttonDisabled = true

      if (!this.user.is_agreement) {
        await this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please accept End User License Agreement',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return
      }

      let isIndividual = 1

      if (this.registrationType === 'company') {
        isIndividual = 0
      }

      if (this.inviteLinkCompany) {
        isIndividual = 0
      }

      try {
        let payload = {
          is_individual: isIndividual,
          user: {
            ...this.user,
            phone_country_iso1: this.phoneInfo.phone_country_iso1,
            phone_country_code1: this.phoneInfo.phone_country_code1,
            phone_number1: this.phoneInfo.phone1,
            address_same_billing: !!this.isSameAddress,
          },
          company: {
            ...this.company,
            phone_country_code: this.phoneInfo.company_phone_country_code,
            phone_country_iso: this.phoneInfo.company_phone_country_iso,
            phone_number: this.phoneInfo.company_phone,
            address_same_billing: !!this.keepBillingOfTheCompany,
          },
        }

        if (this.phoneInfo.phone2) {
          payload = {
            ...payload,
            user: {
              ...payload.user,
              phone_country_iso2: this.phoneInfo.phone_country_iso2,
              phone_country_code2: this.phoneInfo.phone_country_code2,
              phone_number2: this.phoneInfo.phone2,
            },
            company: {
              ...payload.company,
            },
          }
        }

        const { token, id } = this.$route.params

        if (token && id) {
          delete payload.user.email
          payload = {
            ...payload,
            ...{ token },
            ...{ invite_id: id },
          }
          if (!this.isNewCompany) {
            delete payload.company
          }
        }

        await useJwt.register({
          ...payload,
        })

        const toaastTitle = token && id ? 'Register success!' : 'Registration completed successfully! An email has been sent to you with instructions.'

        this.$toast({
          component: ToastificationContent,
          props: {
            title: toaastTitle,
            icon: 'BellIcon',
            variant: 'success',
          },
        })

        this.$router.push({ name: 'auth-login' })
      } catch (error) {
        console.log('error', error)
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }

        if (status === 422) {
          const { fields } = error.response.data
          Object.values(fields).forEach(item => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: item[0],
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
          this.$refs.registerForm.setErrors(fields)
        }
      } finally {
        this.buttonDisabled = false
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
